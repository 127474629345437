import http from '@/utils/http'
import Qs from "qs";

// 手机号登录
export function sms_login(data) {
    return http({
        url: '/u-account/ac-sms_login',
        method: 'get',
        params: data
    })
}

// 密码登录
export function pwd_login(data) {
    return http({
        url: '/u-account/ac-pwd_login',
        method: 'get',
        params: data
    })
}

// 获取图形验证
export function get_cloud_verify(data) {
    return http({
        url: '/u-account/ac-get_cloud_verify',
        method: 'get',
        params: data
    })
}

// 发送短信验证码
export function send_sms(data) {
    return http({
        url: '/u-account/ac-send_sms',
        method: 'get',
        params: data
    })
}

//获取角色
export function get_roles(data) {
    return http({
        url:'/u-account/ac-get_roles',
        method: 'get',
        params: data
    })
}

//选择角色
export function select_role(data) {
    return http({
        url:'/u-account/ac-select_role',
        method: 'get',
        params: data
    })
}

//修改用户主体名称(修改资料)
export function update_holder_name(data) {
    return http({
        url: '/u-base/ac-update_holder_name',
        method: 'post',
        data: Qs.stringify(data)
    })
}
